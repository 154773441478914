import DateWiseReport from "../views/Reports/DateWiseReport.vue";
import BillWiseReport from "../views/Reports/BillWiseReport.vue";
import AreaWiseReport from "../views/Reports/AreaWiseReport.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/DashView.vue"),
  },
  {
    path: "/company",
    name: "Company",
    component: () => import("../views/Masters/CompanyAdd.vue"),
  },
  {
    path: "/countries",
    name: "Countries",
    component: () => import("../views/Masters/CountryList.vue"),
  },
  {
    path: "/states",
    name: "States",
    component: () => import("../views/Masters/StateList.vue"),
  },
  {
    path: "/cities",
    name: "Cities",
    component: () => import("../views/Masters/CityList.vue"),
  },
  {
    path: "/trademarks",
    name: "Trademarks",
    component: () => import("../views/Masters/TrademarkList.vue"),
  },
  {
    path: "/outlets",
    name: "Outlets",
    component: () => import("../views/Masters/OutletList.vue"),
  },
  {
    path: "/pos",
    name: "Pos",
    component: () => import("../views/Masters/PosList.vue"),
  },
  {
    path: "/department",
    name: "Department",
    component: () => import("../views/Masters/DepartmentList.vue"),
  },
  {
    path: "/designation",
    name: "Designation",
    component: () => import("../views/Masters/DesignationList.vue"),
  },
  {
    path: "/areas",
    name: "Areas",
    component: () => import("../views/Masters/AreaList.vue"),
  },
  {
    path: "/sections",
    name: "Sections",
    component: () => import("../views/Masters/SectionList.vue"),
  },
  {
    path: "/tables",
    name: "Tables",
    component: () => import("../views/Masters/TableList.vue"),
  },
  {
    path: "/foodgroups",
    name: "Foodgroups",
    component: () => import("../views/Masters/FoodgroupList.vue"),
  },
  {
    path: "/cusines",
    name: "Cusines",
    component: () => import("../views/Masters/CusineList.vue"),
  },
  {
    path: "/variations",
    name: "Variations",
    component: () => import("../views/Masters/VariationList.vue"),
  },
  {
    path: "/addons",
    name: "Addons",
    component: () => import("../views/Masters/AddonList.vue"),
  },
  {
    path: "/kitchens",
    name: "Kitchens",
    component: () => import("../views/Masters/KitchenList.vue"),
  },
  {
    path: "/kitchenitem",
    name: "Kitchenitem",
    component: () => import("../views/Masters/KitchenitemList.vue"),
  },
  {
    path: "/foods",
    name: "Foods",
    component: () => import("../views/Masters/FoodsList.vue"),
  },
  {
    path: "/matgroups",
    name: "Matgroups",
    component: () => import("../views/Stores/Masters/MatgroupList.vue"),
  },
  {
    path: "/matcatg",
    name: "Matcategory",
    component: () => import("../views/Stores/Masters/MatcategoryList.vue"),
  },
  {
    path: "/matmst",
    name: "Material",
    component: () => import("../views/Stores/Masters/MaterialList.vue"),
  },
  {
    path: "/outletmenu",
    name: "Outletmenu",
    component: () => import("../views/Masters/OutletfoodList.vue"),
  },
  {
    path: "/outletrates",
    name: "OutletRates",
    component: () => import("../views/Masters/RatesList.vue"),
  },
  {
    path: "/employees",
    name: "EmployeeList",
    component: () => import("../views/Masters/EmployeeList.vue"),
  },
  {
    path: "/waiter",
    name: "WaiterList",
    component: () => import("../views/Masters/WaiterList.vue"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Masters/UsersList.vue"),
  },
  {
    path: "/outletuser",
    name: "OutletUser",
    component: () => import("../views/Masters/OutletuserList.vue"),
  },
  {
    path: "/outletareafood",
    name: "OutletAreaFood",
    component: () => import("../views/Masters/OutletareafoodList.vue"),
  },
  {
    path: "/kot",
    name: "KotEnrty",
    component: () => import("@/views/Transaction/KotEntry.vue"),
  },
  {
    path: "/foodimg",
    name: "FoodImages",
    component: () => import("@/views/Masters/FoodImages.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/datewise",
    name: "DateWiseReport",
    component: DateWiseReport,
  },
  {
    path: "/billwise",
    name: "BillWiseReport",
    component: BillWiseReport,
  },
  {
    path: "/areasum",
    name: "AreaWiseReport",
    component: AreaWiseReport,
  },
  {
    path: "/cuisinesum",
    name: "CusisineSumReport",
    component: () => import("../views/Reports/CuisineSumReport.vue"),
  },
  {
    path: "/itemsum",
    name: "ItemSumReport",
    component: () => import("../views/Reports/ItemSumReport.vue"),
  },
  {
    path: "/userwise",
    name: "UserWiseReport",
    component: () => import("../views/Reports/UserWiseReport.vue"),
  },
  {
    path: "/possum",
    name: "PosWiseReport",
    component: () => import("../views/Reports/PosWiseReport.vue"),
  },
  {
    path: "/outletsum",
    name: "OutletWiseReport",
    component: () => import("../views/Reports/OutletWiseReport.vue"),
  },
  {
    path: "/cankotreport",
    name: "CancelKotReport",
    component: () => import("../views/Reports/CancelKotReport.vue"),
  },
  {
    path: "/canbillreport",
    name: "CancelBillReport",
    component: () => import("../views/Reports/CancelBillReport.vue"),
  },
  {
    path: "/outletactions",
    name: "OutletActions",
    component: () => import("../views/Utilities/OutletActions.vue"),
  },
  {
    path: "/rmatmst",
    name: "RmaterialList",
    component: () => import("../views/Masters/RmaterialList.vue"),
  },
  {
    path: "/rmatgroup",
    name: "RmatgroupList",
    component: () => import("../views/Masters/RmatgroupList.vue"),
  },
  {
    path: "/recipe",
    name: "RecipeList",
    component: () => import("../views/Masters/RecipeList.vue"),
  },
  {
    path: "/supplier",
    name: "SupplierList",
    component: () => import("../views/Stores/Masters/SupplierList.vue"),
  },
  {
    path: "/invoice",
    name: "InvocieView",
    component: () => import("../views/InvoiceView.vue"),
  },
  {
    path: "/dpurc",
    name: "PurchaseList",
    component: () => import("@/views/Stores/Transaction/PurchaseList.vue"),
  },
  {
    path: "/matrec",
    name: "MatrecList",
    component: () => import("@/views/Stores/Transaction/MatrecList.vue"),
  },
  {
    path: "/indent",
    name: "IndentList",
    component: () => import("@/views/Stores/Transaction/IndentList.vue"),
  },
  {
    path: "/po",
    name: "Po",
    component: () => import("@/views/Stores/Transaction/PoList.vue"),
  },
  {
    path: "/matiss",
    name: "MatissList",
    component: () => import("@/views/Stores/Transaction/MatissList.vue"),
  },
  {
    path: "/matrtn",
    name: "MatretList",
    component: () => import("@/views/Stores/Transaction/MatretList.vue"),
  },
  {
    path: "/couponlist",
    name: "CouponList",
    component: () => import("@/views/Masters/CouponmstList.vue"),
  },
  {
    path: "/cpoutletlist",
    name: "CouponOutletList",
    component: () => import("@/views/Masters/CouponOutletList.vue"),
  },
  {
    path: "/stkrep",
    name: "StockReport",
    component: () => import("@/views/Stores/Reports/StockReport.vue"),
  },
  {
    path: "/phystk",
    name: "PhysicalstockList",
    component: () => import("@/views/Stores/Transaction/PhysicalstockList.vue"),
  },
  {
    path: "/purcpo",
    name: "PurcpoList",
    component: () => import("@/views/Stores/Transaction/PurcpoList.vue"),
  },
  {
    path: "/shortage",
    name: "ShortageList",
    component: () => import("@/views/Stores/Transaction/ShortageList.vue"),
  },
  {
    path: "/activefoods",
    name: "ActiveFoods",
    component: () => import("../views/Masters/ActiveFoods.vue"),
  },
];

export default routes;
