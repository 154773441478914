<!-- @format -->

<template>
  <div class="form-div" v-show="!addForm">
    <teleport to="#app">
      <confirm-box ref="confirmbox"></confirm-box>
    </teleport>
    <teleport to="#app">
      <bill-detail ref="billdetail" @on-close="closeDetail"> </bill-detail>
    </teleport>
    <teleport to="#app">
      <message-box ref="msgbox"></message-box>
    </teleport>
    <alert-box
      :alertText="errMsg"
      :alertType="errType"
      ref="alertbox"></alert-box>
    <div class="title-bar">
      <div class="title-text">{{ fromName }}</div>
      <close-btn />
    </div>
    <div class="div-content">
      <div class="filters">
        <div class="form-group">
          <label>From Date:</label>
          <input type="date" class="form-control dt" v-model="mdate1" />
        </div>
        <div class="form-group">
          <label>To Date:</label>
          <input type="date" class="form-control dt" v-model="mdate2" />
        </div>
        <div class="form-group">
          <label for="outletname">Outlet Name :<mandatory-icon /></label>
          <select
            class="select-control"
            ref="outletname"
            name="outletname"
            v-model="outletid">
            <option value="0">--All--</option>
            <option
              v-for="rw in outletlist"
              :key="rw.outletid"
              :value="rw.outletid">
              {{ rw.shortname + " | " + rw.outletname }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group rd-btns">
        <input type="radio" name="rd" value="1" id="rd1" v-model="rdbtn" />
        <label for="rd1">All</label>

        <input type="radio" name="rd" value="2" id="rd2" v-model="rdbtn" />
        <label for="rd2">Cash</label>

        <input type="radio" name="rd" value="3" id="rd3" v-model="rdbtn" />
        <label for="rd3">Credit Card</label>

        <input type="radio" name="rd" value="4" id="rd4" v-model="rdbtn" />
        <label for="rd4">UPI</label>

        <input type="radio" name="rd" value="5" id="rd5" v-model="rdbtn" />
        <label for="rd5">Online(Dineout)</label>

        <input type="radio" name="rd" value="6" id="rd6" v-model="rdbtn" />
        <label for="rd6">Zomato</label>

        <input type="radio" name="rd" value="7" id="rd7" v-model="rdbtn" />
        <label for="rd7">Swiggy</label>

        <input type="radio" name="rd" value="8" id="rd8" v-model="rdbtn" />
        <label for="rd8">F.O.C.</label>
        <input type="radio" name="rd" value="9" id="rd9" v-model="rdbtn" />
        <label for="rd9">APP.</label>
      </div>
      <div class="btn-area">
        <button type="button" class="btn btn-primary" @click="filterRep">
          <span class="fas fa-filter"></span>
          Filter
        </button>
        <button type="button" class="btn btn-warn" @click="clearFilter">
          <span class="fas fa-filter"> </span>
          <i class="fas fa-times"></i>
          Clear Filter
        </button>

        <!-- <button
          type="button"
          class="btn btn-default exportbtn"
          @click="printRep"
        >
            <span class="fa-solid fa-print"></span> Print Report
        </button> -->

        <button
          type="button"
          class="btn btn-default exportbtn"
          @click="exportPdf">
          <span class="fas fa-file-pdf"></span> Export PDF
        </button>
        <button
          type="button"
          class="btn btn-default exportbtn"
          @click="exportExcel">
          <span class="fas fa-file-excel"></span> Export Excel
        </button>
        <div class="exportlink">
          <a :href="fileLink">{{ fileName }}</a>
        </div>
      </div>
      <div class="filter-area">
        <input
          type="text"
          class="form-control searchbox"
          placeholder="Search"
          v-model="search"
          @keyup="searchRec"
          ref="search"
          autofocus />
      </div>
      <vue3-simple-html2pdf
        ref="vue3SimpleHtml2pdf"
        :options="pdfOptions"
        :filename="exportFilename">
        <div class="grid-area">
          <div class="pdf-title" v-if="exporting">
            <div class="company-name">{{ companyname }}</div>
            {{ caption }} From:{{ repdt1 }} To:
            {{ repdt2 }}
          </div>
          <card-loader v-if="loading"></card-loader>
          <table v-else>
            <thead>
              <tr>
                <th class="srnocol toleft">#</th>
                <th class="toleft">Invoice No.</th>
                <th class="toleft">Date & Time</th>
                <th class="toleft">Table</th>
                <th class="toright">Gross Amount</th>
                <th class="toright">Discount</th>
                <th class="toright">Taxable</th>
                <th class="toright">SGST</th>
                <th class="toright">CGST</th>
                <th class="toright">Roundoff</th>
                <th class="toright">Net Amount</th>
                <th class="toright">Other Charges</th>
                <th class="toright">Cash</th>
                <th class="toright">Card</th>
                <th class="toright">UPI</th>
                <th class="toright">Zomato <br />/ Swiggy</th>
                <th class="toright">Others</th>
                <th class="toright">App.</th>
                <th class="toleft">Customer</th>
                <th class="toleft">Outlet</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in mstList"
                :key="row.index"
                @click="openDetails(row)">
                <td>{{ index + 1 }}</td>
                <td>{{ row.ser }}-{{ row.docno }}</td>
                <td class="dtm">{{ row.dt }} {{ row.ttime }}</td>
                <td class="tocenter">{{ row.tableno }}</td>
                <td class="toright">{{ row.amount1 }}</td>
                <td class="toright">{{ row.dis }}</td>
                <td class="toright">{{ row.taxable }}</td>
                <td class="toright">{{ row.sgst }}</td>
                <td class="toright">{{ row.cgst }}</td>
                <td class="toright">{{ row.misc }}</td>
                <td class="toright">{{ row.billamt }}</td>
                <td class="toright">
                  {{
                    parseFloat(row.exp1) +
                    parseFloat(row.exp2) +
                    parseFloat(row.exp3)
                  }}
                </td>
                <td class="toright">{{ row.pay1 }}</td>
                <td class="toright">{{ row.pay2 }}</td>
                <td class="toright">{{ row.pay3 }}</td>
                <td class="toright">{{ row.pay4 }}</td>
                <td class="toright">{{ row.pay5 }}</td>
                <td class="toright">{{ row.pay6 }}</td>
                <td>{{ row.custname }}</td>
                <td>{{ row.outletname }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="toright">Total</td>
                <td class="toright">{{ getTotal(mstList, "amount1") }}</td>
                <td class="toright">{{ getTotal(mstList, "dis") }}</td>
                <td class="toright">{{ getTotal(mstList, "taxable") }}</td>
                <td class="toright">{{ getTotal(mstList, "sgst") }}</td>
                <td class="toright">{{ getTotal(mstList, "cgst") }}</td>
                <td class="toright">{{ getTotal(mstList, "misc") }}</td>
                <td class="toright">{{ getTotal(mstList, "billamt") }}</td>
                <td class="toright">
                  {{ getTotal(mstList, ["exp1", "exp2", "exp3"]) }}
                </td>
                <td class="toright">{{ getTotal(mstList, "pay1") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay2") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay3") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay4") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay5") }}</td>
                <td class="toright">{{ getTotal(mstList, "pay6") }}</td>
                <td colspan="2"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </vue3-simple-html2pdf>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MyFun from "@/js/MyFun";
import BillDetail from "@/components/BillDetail.vue";

export default {
  components: {
    BillDetail,
  },
  data() {
    return {
      formId: "102",
      fromName: "Bill Wise Sale",
      permission: [],
      addForm: false,
      optn: "Add",
      search: "",
      mstList: [],
      outletlist: [],
      userId: this.$store.state.userId,
      userName: this.$store.state.userName,
      outletid: "",
      outletname: "",
      companyname: this.$store.state.tradeMark,
      errMsg: "",
      errType: "",
      alert: false,
      loading: false,
      fileName: "",
      fileLink: "",
      pageName: "reports/billwisereport.php",
      mdate1: MyFun.getdtnow(),
      mdate2: MyFun.getdtnow(),
      mytimer: null,
      repdt1: null,
      repdt2: null,
      caption: "Bill Wise Sale",
      exportFilename: "Bill_wise_Sale.pdf",
      pdfOptions: {
        margin: 10,
        image: {
          type: "jpeg",
          quality: 1,
        },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "l",
        },
      },
      exporting: false,
      pageNumber: 0,
      rdbtn: 1,
    };
  },
  methods: {
    async filterRep() {
      this.loading = true;
      await axios
        .post(this.pageName, {
          optn: "Report",
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          outletid: this.outletid,
          msearch: this.search,
          rdbtn: this.rdbtn,
          uid: "",
          uname: "",
        })
        .then((response) => {
          // console.log(response);
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.mstList = resp.List;
          } else {
            this.$refs.msgbox.show({
              title: "Oops!",
              message: "Something Went Wrong!" + resp.ErrorMsg,
              okButton: "Ok",
              msgboxType: "danger",
            });
          }
        });
      this.loading = false;
    },
    getTotal(arr, field) {
      var i = 0;
      var total = 0;
      for (i = 0; i < arr.length; i++) {
        var myval = arr[i];
        if (typeof field == "string") {
          total += parseFloat(myval[field]);
        } else {
          if (typeof field == "object") {
            for (var k = 0; k < field.length; k++) {
              total += parseFloat(myval[field[k]]);
            }
          }
        }
      }
      return total.toFixed(2);
    },
    async exportExcel() {
      this.fileName = "Exporting...";
      await axios
        .post(this.pageName, {
          optn: "Exportxls",
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          outletid: this.outletid,
          uid: this.userId,
          uname: this.userName,
          msearch: this.search,
          rdbtn: this.rdbtn,
        })
        .then((response) => {
          var resp = response.data;
          if (resp.Errorsts == false) {
            this.fileName = resp.FileName;
            this.fileLink = resp.FileLink;
          } else {
            this.$refs.msgbox.show({
              title: "Oops!",
              message: "Something Went Wrong!<br/>" + resp,
              okButton: "Ok",
              msgboxType: "danger",
            });
            console.log("Error:" + this.ErroMsg);
          }
        });
    },
    async exportPdf() {
      this.exporting = true;
      var grid = document.getElementsByClassName("grid-area")[0];
      grid.classList.add("printgrid-area");
      await this.$refs.vue3SimpleHtml2pdf.download();
      setTimeout(() => {
        this.exporting = false;
        grid.classList.remove("printgrid-area");
      }, 2500);
    },
    searchRec() {
      if (this.mytimer != null) {
        clearTimeout(this.mytimer);
      }
      this.mytimer = setTimeout(() => {
        this.filterRep();
      }, 500);
    },
    activateForm() {
      setTimeout(() => {
        this.$refs.search.focus();
      }, 100);
    },
    async getOutlets() {
      await axios
        .post("outlets.php", {
          optn: "List",
          msearch: "",
          uid: this.userId,
          uname: this.userName,
        })
        .then((response) => {
          if (response.data.Errorsts == false) {
            this.outletid = this.$store.state.OutletId;
            this.outletlist = response.data.List;
            if (this.$store.state.OutletId != "0") {
              this.$refs.outletname.disabled = true;
              this.outletlist = this.outletlist.filter((row) => {
                return row["outletid"].includes(this.$store.state.OutletId);
              });
            } else {
              this.$refs.outletname.disabled = false;
              this.outletlist = response.data.List;
            }
          }
        });
    },
    async printRep() {
      axios
        .post("printrep.php", {
          action: "Add",
          mdate1: this.mdate1,
          mdate2: this.mdate2,
          outletid: this.outletid,
          lookfor: this.search,
          repoid: this.formId,
          reponame: this.fromName,
        })
        .then((response) => {
          console.log(response);
        });
    },
    async openDetails(row) {
      this.$refs.billdetail.showBill(row);
    },
    async closeDetail() {
      this.$refs.billdetail.hideBill();
    },
    async clearFilter() {
      this.search = "";
      this.filterRep();
    },
  },
  async mounted() {
    await this.getOutlets();
    await this.filterRep();
    this.repdt1 = MyFun.tobritish(this.mdate1);
    this.repdt2 = MyFun.tobritish(this.mdate2);
    this.permission = await MyFun.getPermissions(this.userId, this.formId);
  },
};
</script>

<style>
@import "../../css/base.css";
@import "../../css/report.css";
</style>
<style scoped>
.dt {
  width: 110px;
}
.filters {
  position: relative;
  display: block;
  padding: 5px;
  width: calc(100% - 15px);
}

.filters .form-group {
  position: relative;
  display: inline-block;
}
.btn-area .btn {
  font-size: 16px;
  padding: 6px;
}
.btn-area .btn .fa-filter {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}
.btn-area .btn .fa-times {
  position: absolute;
  left: 12px;
  top: 14px;
  font-size: 14px;
  color: red;
}

.grid-area table tfoot tr td {
  padding: 6px;
}
.company-name {
  position: relative;
  display: block;
  width: 100%;
  font-size: 25px;
  font-weight: bold;
}
.fa-file-excel {
  font-size: 16px;
  color: green;
}
.fa-file-pdf {
  font-size: 16px;
  color: red;
}

.rd-btns label {
  position: relative;
  top: -2px;
  font-weight: bold;
  left: 5px;
  right: 5px;
}

@media only screen and (max-width: 600px) {
  .exportbtn {
    float: none;
  }
  .btn-area .btn .fa-times {
    position: absolute;
    left: 14px;
    top: 14px;
    font-size: 12px;
    color: red;
  }

  .btn-area .btn {
    font-size: 14px;
    padding: 6px;
  }
}
</style>
